import logo from './male-technologist.png';
import MyTerminal from './components/Terminal';
import './App.css';

function App() {
  return (
      <MyTerminal/>
  );
}

export default App;
